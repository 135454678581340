import { createSlice } from '@reduxjs/toolkit';

export const medicalHistoriesSlice = createSlice({
  name: 'medicalHistories',
  initialState: [],
  // Redux Toolkit allows us to write "mutating" logic in reducers. It
  // doesn't actually mutate the state because it uses the Immer library,
  // which detects changes to a "draft state" and produces a brand new
  // immutable state based off those changes
  reducers: {
    replaceMedicalHistories: (state, action) => {
      return action.payload;
    },
  },
});

export const { replaceMedicalHistories } = medicalHistoriesSlice.actions;

// Selectors ******************************************************************
// The functions below are called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`

export const selectAll = (state) =>
  [...state.medicalHistories].sort((a, b) => (a.name > b.name ? 1 : -1));

export default medicalHistoriesSlice.reducer;

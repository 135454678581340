import { createSlice } from '@reduxjs/toolkit';

export const hrtsSlice = createSlice({
  name: 'hrts',
  initialState: { categories: {}, items: [] },
  // Redux Toolkit allows us to write "mutating" logic in reducers. It
  // doesn't actually mutate the state because it uses the Immer library,
  // which detects changes to a "draft state" and produces a brand new
  // immutable state based off those changes
  reducers: {
    replaceHrts: (state, action) => {
      return {
        categories: action.payload.hrtCategories,
        items: action.payload.hrts,
      };
    },
  },
});

export const { replaceHrts } = hrtsSlice.actions;

// Selectors ******************************************************************
// The functions below are called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`

export const selectAllCategories = (state) => {
  return Object.values(state.hrts.categories).sort((a, b) => (a > b ? 1 : -1));
};

export const selectAllItems = (state) =>
  [...state.hrts.items].sort((a, b) => (a.name > b.name ? 1 : -1));

export default hrtsSlice.reducer;

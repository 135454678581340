import { createSlice } from '@reduxjs/toolkit';

export const contactTopicsSlice = createSlice({
  name: 'contactTopics',
  initialState: [
    {
      _id: 1,
      name: 'General Enquiry',
      enabled: true,
    },
    {
      _id: 2,
      name: 'Refund',
      enabled: true,
    },
    {
      _id: 3,
      name: 'Technical Issue',
      enabled: true,
    },
  ],
  // Redux Toolkit allows us to write "mutating" logic in reducers. It
  // doesn't actually mutate the state because it uses the Immer library,
  // which detects changes to a "draft state" and produces a brand new
  // immutable state based off those changes
  reducers: {},
});

// Selectors ******************************************************************
// The functions below are called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`

export const selectAll = (state) =>
  state.contactTopics.sort((a, b) => (a.name > b.name ? 1 : -1));

export default contactTopicsSlice.reducer;
